export default function useSeo() {
  const { public: config, app } = useRuntimeConfig()
  const searchSnippet = () => {
    useHead({
      script: [
        {
          hid: 'WebSite',
          type: 'application/ld+json',
          innerHTML: {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            'url': config.baseDomain + app.baseURL,
            'potentialAction': {
              '@type': 'SearchAction',
              'target': `${config.baseDomain + app.baseURL}search/?q={search_term_string}`,
              'query-input': 'required name=search_term_string',
            },
          },
        },
      ],
    })
  }

  const productSnippet = (product: any) => {
    const { locale, locales } = useI18n({ useScope: 'global' })
    const currency = locales.value.find(lcl => lcl.code === locale.value)?.numberFormats.currency.currency || 'EUR'

    const availability = 'https://schema.org/InStock'
    if (!product.stockStatus) {
      // availability = 'https://schema.org/OutOfStock'
    }
    useHead({
      script: [
        {
          hid: 'Product',
          type: 'application/ld+json',
          innerHTML: {
            '@context': 'https://schema.org',
            '@type': 'Product',
            'name': `${product.name} ${product.descriptiveName}`,
            'image': `${config.xcdnUrl}OQ75_RM830,1066,255,255,255/-/catalog/product${product.image}`,
            // 'description': this.metaDescription,
            'sku': product.sku,
            'brand': {
              '@type': 'Brand',
              'name': product.brand?.label,
            },
            'offers': {
              '@type': 'Offer',
              // url: `${config.baseUrl}${config.app.baseURL}${product.url}`.replace(/(?<!:)\/\/+/g, '/'),
              'url': `${config.baseDomain}${app.baseURL}${product.url}`.replace(/\/+/g, '/').replace(':/', '://'), // Safari compatible version
              'priceCurrency': currency,
              'price': product.finalPrice || 0,
              'itemCondition': 'https://schema.org/NewCondition',
              availability,
            },
          },
        },
      ],
    })
  }

  const canonical = (url: Ref<string>) => {
    const { public: config, app } = useRuntimeConfig()
    const href = computed(
      // () => (baseUrl + baseURL + url.value).replace(/(?<!:)\/\/+/g, '/')
      () => (config.baseDomain + app.baseURL + url.value).replace(/\/+/g, '/').replace(':/', '://'), // Safari compatible version
    )
    useHead({
      link: [
        { hid: 'canonical', rel: 'canonical', href },
      ],
    })
  }

  return {
    productSnippet,
    searchSnippet,
    canonical,
  }
}
